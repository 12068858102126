import React, { useEffect, useState } from 'react';
import Header from '../directives/header';
import Footer from '../directives/footer';
import config from '../config/config'
import axios from 'axios'
import ContentShimmer, { SocialShimmer, CodeShimmer, ProfileShimmer } from 'react-content-shimmer'
import Web3 from 'web3';

function Marketplace() {

  useEffect(() => {
    getNFT();
    var acc = document.getElementsByClassName("accordion");
    var i;

    for (i = 0; i < acc.length; i++) {
      acc[i].addEventListener("click", function () {
        this.classList.toggle("active");
        var panel = this.nextElementSibling;
        if (panel.style.display === "block") {
          panel.style.display = "none";
        } else {
          panel.style.display = "block";
        }
      });
    }
  }, []);
  const [getTotalNFT, setgetTotalNFT] = useState(0);
  const [totalNftForView, settotalNftForView] = useState(0);

  const [nftMainList, setnftMainList] = useState([]);
  const [nftList, setnftList] = useState([]);
  const [loaderList, setloaderList] = useState(['', '', '', '', '', '', '', '', ''])
  const [isLoading, setisLoading] = useState(true);
  const [traitType, settraitType] = useState([]);
  const [filterData, setfilterData] = useState([]);
  const [tabCreate, settabCreate] = useState(0);

  // const [ActiveBackground, setActiveBackground] = useState(false);
  // const [ActiveClothes, setActiveClothes] = useState(false);
  // const [ActiveEyes, setActiveEyes] = useState(false);
  // const [ActiveFur, setActiveFur] = useState(false);
  // const [ActiveHat, setActiveHat] = useState(false);
  // const [ActiveMouth, setActiveMouth] = useState(false);
  // const [ActiveCollection, setActiveCollection] = useState(false);
  const [ActivePanel, setActivePanel] = useState({
    Clothes: false,
    Eyes: false,
    Fur: false,
    Hat: false,
    Mouth: false,
    Collection: false,
    Background: false,
    Legendary:false
  })

  const getNFT = async () => {
    await axios({
      method: 'get',
      url: 'https://nft.oddfellowsnft.com/api/nft/list',
    }).then(async response => {
      await setnftMainList(response.data);
      setTimeout(() => {
        getMoreNFT(response.data);
        getAllFilters(response.data);
      }, 1000);
    })

  }

  const getAllFilters = async (arr) => {
    const newList = await arr.filter((item, i) => {

      let attributes = item.attributes;
      let taitTypeArray = traitType;
      attributes.filter(row => {
        if (taitTypeArray.indexOf(row.trait_type) < 0) {
          let ar = taitTypeArray[row.trait_type] || [];
          if (ar.indexOf(row.value) < 0) {
            ar.push(row.value);
          }
          taitTypeArray[row.trait_type] = ar;
        }
      })
      settraitType(taitTypeArray);
    });
  }

  const getMoreNFT = async (mainData = [], num = 0, isFilter = false) => {
    let number = parseInt(num) + 9;
    let array = mainData;
    if (mainData.length == 0 && !isFilter) {
      array = nftMainList;
    }
    settotalNftForView(array.length);

    const newList = await array.filter((item, i) => {
      if (i >= number) {
        return;
      }
      return item;
    });

    setnftList(newList);
    setgetTotalNFT(number);
    setTimeout(() => {
      setisLoading(false)
    }, 200);
  }

  const filterSelect = async (main, sub) => {

    let newfilterData = filterData;
    let number = getTotalNFT;
    if (sub) {
      number = 0;
      let data = filterData;
      let arr = filterData[main];
      if (arr == sub) {
        delete filterData[main];
      } else {
        data = {
          ...filterData,
          [main]: sub
        }
      }



      setfilterData(data);
      newfilterData = data;
    }

    const array = nftMainList.filter(item => {
      let attributes = item.attributes;
      let ff = 0;
      let filterchk = attributes.filter(function (row) {
        let ar = newfilterData[row.trait_type];
        if (ar == row.value) {
          ff++;
        }
      });
      var count = Object.keys(newfilterData).length;
      if (ff == count) {
        return item;
      }

    });
    openPanel()
    getMoreNFT(array, number, true);
  }

  const resetFilter = () => {
    setfilterData([])
    getMoreNFT([], 0, false);

  }
  const openPanel = (type) => {
    setActivePanel({
      Clothes: (type == 'Clothes') ? !ActivePanel.Clothes : false,
      Eyes: (type == 'Eyes') ? !ActivePanel.Eyes : false,
      Fur: (type == 'Fur') ? !ActivePanel.Fur : false,
      Hat: (type == 'Hat') ? !ActivePanel.Hat : false,
      Mouth: (type == 'Mouth') ? !ActivePanel.Mouth : false,
      Collection: (type == 'Collection') ? !ActivePanel.Collection : false,
      Background: (type == 'Background') ? !ActivePanel.Background : false,
      Legendary: (type == 'Legendary') ? !ActivePanel.Legendary : false,
    })
  }

  return (

    <>
      <Header />
      <div className="main-content">

        <section id="characters" className="characters content-section mt-5 latest-articles text-light">
          <br />
          <div className="container mt-5 pt-5">
            <div className="row">
              <div className="col-sm-3 col-md-3 col-lg-3 mx-auto mb-7 md-lg-0">

                {isLoading ||
                  <div className="filter">

                    <button className="accordion mt-2" onClick={e => openPanel('Background')}>Background {!filterData.Background || ' - ' + filterData.Background}</button>
                    <div className="panel" style={{ display: (ActivePanel.Background) ? 'block' : 'none' }}>
                      <ul>
                        {traitType.Background.map((item) => (
                          <li onClick={e => filterSelect('Background', item)} className={(filterData.Background == item) ? 'active' : ''}>{item}</li>
                        ))}
                      </ul>
                    </div>

                    <button className="accordion mt-2" onClick={e => openPanel('Clothes')}>Clothes {!filterData.Clothes || ' - ' + filterData.Clothes}</button>
                    <div className="panel" style={{ display: (ActivePanel.Clothes) ? 'block' : 'none' }}>
                      <ul>
                        {traitType.Clothes.map((item) => (
                          <li onClick={e => filterSelect('Clothes', item)} className={(filterData.Clothes == item) ? 'active' : ''}>{item}</li>
                        ))}
                      </ul>
                    </div>

                    <button className="accordion mt-2" onClick={e => openPanel('Eyes')}>Eyes {!filterData.Eyes || ' - ' + filterData.Eyes}</button>
                    <div className="panel" style={{ display: (ActivePanel.Eyes) ? 'block' : 'none' }}>
                      <ul>
                        {traitType.Eyes.map((item) => (
                          <li onClick={e => filterSelect('Eyes', item)} className={(filterData.Eyes == item) ? 'active' : ''}>{item}</li>
                        ))}
                      </ul>
                    </div>


                    <button className="accordion mt-2" onClick={e => openPanel('Fur')}>Fur {!filterData.Fur || ' - ' + filterData.Fur}</button>
                    <div className="panel" style={{ display: (ActivePanel.Fur) ? 'block' : 'none' }}>
                      <ul>
                        {traitType.Fur.map((item) => (
                          <li onClick={e => filterSelect('Fur', item)} className={(filterData.Fur == item) ? 'active' : ''}>{item}</li>
                        ))}
                      </ul>
                    </div>

                    <button className="accordion mt-2" onClick={e => openPanel('Hat')}>Hat {!filterData.Hat || ' - ' + filterData.Hat}</button>
                    <div className="panel" style={{ display: (ActivePanel.Hat) ? 'block' : 'none' }}>
                      <ul>
                        {traitType.Hat.map((item) => (
                          <li onClick={e => filterSelect('Hat', item)} className={(filterData.Hat == item) ? 'active' : ''}>{item}</li>
                        ))}
                      </ul>
                    </div>

                    <button className="accordion mt-2" onClick={e => openPanel('Mouth')}>Mouth {!filterData.Mouth || ' - ' + filterData.Mouth}</button>
                    <div className="panel" style={{ display: (ActivePanel.Mouth) ? 'block' : 'none' }}>
                      <ul>
                        {traitType.Mouth.map((item) => (
                          <li onClick={e => filterSelect('Mouth', item)} className={(filterData.Mouth == item) ? 'active' : ''}>{item}</li>
                        ))}
                      </ul>
                    </div>

                    <button className="accordion mt-2" onClick={e => openPanel('Collection')}>Collection {!filterData.Collection || ' - ' + filterData.Collection}</button>
                    <div className="panel" style={{ display: (ActivePanel.Collection) ? 'block' : 'none' }}>
                      <ul>
                        {traitType.Collection.map((item) => (
                          <li onClick={e => filterSelect('Collection', item)} className={(filterData.Collection == item) ? 'active' : ''}>{item}</li>
                        ))}
                      </ul>
                    </div>

                    <button className="accordion mt-2" onClick={e => openPanel('Legendary')}>Legendary {!filterData.Legendary || ' - ' + filterData.Legendary}</button>
                    <div className="panel" style={{ display: (ActivePanel.Legendary) ? 'block' : 'none' }}>
                      <ul>
                        {traitType.Legendary.map((item) => (
                          <li onClick={e => filterSelect('Legendary', item)} className={(filterData.Legendary == item) ? 'active' : ''}>{item}</li>
                        ))}
                      </ul>
                    </div>
                    

                    <button className="btn-border btn mt-5 " style={{ width: '100%' }} onClick={e => resetFilter()}>RESET FILTERS</button>

                  </div>
                }


                {!isLoading ||
                  loaderList.map(item => (
                    <div className="filter">
                      <button className="accordion mt-2" style={{ background: 'linear-gradient(to right, rgb(223 223 223) 8%, rgb(211 211 211) 18%, rgb(223 223 223) 33%)' }}></button>
                    </div>
                  ))}


              </div>

              <div className="col-sm-9 col-md-9 col-lg-9 mx-auto mb-7 md-lg-0">
                <div className="marketplace">
                  <h3>Secret Society of Odd Fellows (SSOF) Gallery</h3>
                  <h6>{totalNftForView} Items</h6>
                  <div className="row">
                    {nftList.map(item => (
                      <div className="col-sm-4 col-6">
                        <div className="box-market">
                          <a href={`https://rarible.com/token/0xb121db250735c639421592e428a0aef420d40a73:${item.name.split('#')[1]}`} target="_blank">
                            <img src={item.image} style={{ background: '#ddd' }} />
                            <h4>{item.name}</h4>
                          </a>
                        </div>
                      </div>
                    ))}

                    {!isLoading ||
                      loaderList.map(item => (
                        <div className="col-sm-4 col-6">
                          <div className="box-market">
                            <ContentShimmer style={{ width: 'unset', height: "230px", background: 'linear-gradient(to right, rgb(223 223 223) 8%, rgb(211 211 211) 18%, rgb(223 223 223) 33%)' }} className="img-banner" rounded={"10px"} />
                            <h4>#00</h4>
                          </div>
                        </div>
                      ))}


                    {nftList.length > 0 ||
                      <div className='col-sm-12 col-md-12 text-center'>
                        <p>No Item Available</p>
                      </div>
                    }

                    {isLoading || nftList.length == 0 ||
                      <div className='col-sm-12 col-md-12 text-center'>
                        <button className='btn-border btn' onClick={e => filterSelect()}>Load More</button>
                      </div>
                    }

                  </div>
                </div>
              </div>


            </div>
          </div>
        </section>

      </div>

      <Footer />

    </>
  );
}

export default Marketplace;