import React, { useEffect, useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import config from "../config/config";
import { NavLink } from "react-router-dom";
import Fortmatic from "fortmatic";
import Web3 from "web3";
import toast, { Toaster } from "react-hot-toast";
import Onboard from "bnc-onboard";
import Swal from "sweetalert2";
import AnchorLink from "react-anchor-link-smooth-scroll";
import RewardMenu from "../Components/reward";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";

let web3;

const onboard = Onboard({
  dappId: "a830de3a-cbe0-4fc2-9242-5c9c406f6fb2",
  networkId: 0x1,
  subscriptions: {
    wallet: (wallet) => {
      web3 = new Web3(wallet.provider);
    },
  },

  walletSelect: {
    wallets: [
      {
        walletName: "metamask",
      },
      {
        walletName: "coinbase",
      },
    ],
  },
});

const Header = (props) => {
  const fm = new Fortmatic("pk_test_644846FACB88893F", "ropsten");
  const web3 = new Web3(fm.getProvider());

  const [connectWalletAddress, setConnectWalletAddress] = useState("");
  const [isConnectMetamask, setisConnectMetamask] = useState(false);
  const [isConnectCoinbase, setisConnectCoinbase] = useState(false);
  const [ismetamaskconnect, setismetamaskconnect] = useState(false);
  // const [ismetamaskconnect1, setismetamaskconnect1] = useState(false)

  const [iscoinbaseconnect, setiscoinbaseconnect] = useState(false);
  const [navOpen, setnavOpen] = useState(false);
  const [walletPoup, setWalletPopup] = useState(props.walletConnectFun);

  // const [iscoinbaseconnect1, setiscoinbaseconnect1] = useState(false)

  const [state, setState] = useState({
    isPaneOpen: false,
    isPaneOpenLeft: false,
  });

  //  walletConnectFun
  const setDefault = async () => {
    setismetamaskconnect(false);
    // setismetamaskconnect1(false)

    setiscoinbaseconnect(false);
    // setiscoinbaseconnect1(false)
  };

  const cwalletClick = async () => {
    setismetamaskconnect(false);
    // setismetamaskconnect1(false)

    setiscoinbaseconnect(false);
  };

  async function openMetamask() {
    await onboard.walletSelect();
    connectMetaMask();
    connectMetaMask1();
  }

  const connectMetaMask = async () => {
    if (window.ethereum) {
      var isMetaMask = await window.ethereum.isMetaMask;
      if (!isMetaMask) {
        setismetamaskconnect(true);
        // toast.error(`Please install Metamask wallet to use this App!`);
        return;
      }

      if (!window.ethereum.providers) {
        var metamaskProvider = await window.ethereum;
      } else {
        var metamaskProvider = await window.ethereum.providers.find(
          (provider) => provider.isMetaMask
        );
      }
      try {
        const accounts = await metamaskProvider.request({
          method: "eth_requestAccounts",
        });
        setConnectWalletAddress(accounts[0]);
        setisConnectMetamask(true);
        localStorage.setItem("walletType", "metamask");
        localStorage.setItem("connectWalletAddress", accounts[0]);
        localStorage.setItem("isLogout", false);
        window.location.reload();
      } catch (err) {
        Swal.fire({
          text: err.message,
          // icon: 'warning',
          title: "Alert",
          confirmButtonText: "Okay",
          confirmButtonColor: "#ae7d5c",
          background: "#311a12",
          color: "#fff",
        });
      }
    } else {
      setismetamaskconnect(true);
      // toast.error(`Please install MetaMask to use this App!`, {

      // });
    }
  };

  const connectMetaMask1 = async () => {
    if (window.ethereum) {
      if (!window.ethereum.providers) {
        if (window.ethereum.isCoinbaseWallet == true) {
          var coinbaseProvider = await window.ethereum;
        } else {
          // alert('44422223333')

          setiscoinbaseconnect(true);
          // toast.error(`Please install Coinbase wallet to use this App!`);
          return;
        }
      } else {
        var coinbaseProvider = await window.ethereum.providers.find(
          (provider) => provider.isCoinbaseWallet
        );
      }

      try {
        const accounts = await coinbaseProvider.request({
          method: "eth_requestAccounts",
        });
        setConnectWalletAddress(accounts[0]);
        localStorage.setItem("walletType", "coinbase");
        localStorage.setItem("connectWalletAddress", accounts[0]);
        localStorage.setItem("isLogout", false);
        window.location.reload();
        setisConnectCoinbase(true);
      } catch (err) {
        Swal.fire({
          text: err.message,
          // icon: 'warning',
          title: "Alert",
          confirmButtonText: "Okay",
          confirmButtonColor: "#ae7d5c",
          background: "#311a12",
          color: "#fff",
        });
      }
    } else {
      // alert('4442222wqewq')

      setiscoinbaseconnect(true);
      // toast.error(`Please install Coinbase to use this App!`, {

      // });
    }
  };

  useEffect(() => {
    // if (typeof window.ethereum !== 'undefined') {
    //   console.log('MetaMask is installed!');
    // }
    // connectMetaMask()
    setTimeout(() => {
      if (window.ethereum && localStorage.getItem("isLogout")) {
        const { ethereum } = window;
        if (ethereum.selectedAddress) {
          var web3 = new Web3(window.ethereum);
          var currentNetwork = web3.currentProvider.chainId;
          // if (currentNetwork != '56' && currentNetwork != '0x61') {
          //     Cookies.set('loginSuccessMetaseer', "");
          //     return;
          // }
          setConnectWalletAddress(ethereum.selectedAddress);
        }
      }
    }, 1000);
  }, []);

  const accountLogout = async () => {
    localStorage.setItem("walletType", "");
    localStorage.setItem("connectWalletAddress", "");
    localStorage.setItem("isLogout", true);
    window.location.reload();
  };

  return (
    <>
      <Toaster position="top-right" reverseOrder={false} />
      <div className="header-fixed fixed-top box-shadow border-nav bg-chocalate">
        <div className="container">
          <div className="row">
            <div className="col-lg-2 col-4 col-sm-3 col-md-2 mr-auto">
              <a className="navbar-brand logo" href={`${config.baseUrl}`}>
                <img
                  src="assets/img/content/logo-nft.png"
                  alt="Wicodus"
                  className="logo-light mx-auto"
                />
              </a>
            </div>
            <div className="col-8 col-sm-9 col-md-10 col-lg-10   text-right">
              <nav className="navbar navbar-expand-lg  ">
                <div className="row mt-5">
                  <div className="discord_btn_mob">
                    <div id="buy-an-ape" className="buy-token-container">
                      <a
                        href="https://discord.com/invite/oddfellowsnft"
                        className="discord_btn"
                      >
                        <img
                          src="assets/img/discord_btn.png"
                          className="btn_discord"
                        ></img>
                      </a>
                    </div>
                  </div>
                  <button
                    className="navbar-toggler navbar-toggler-fixed mt-5"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapsingNavbar"
                    aria-controls="collapsingNavbar"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    ☰
                  </button>
                  <div
                    className="collapse navbar-collapse"
                    id="collapsingNavbar"
                  >
                    <MobileView>
                      <ul className="navbar-nav mr-auto pt-md-1 ">
                        <li className="nav-item">
                          <AnchorLink
                            className="nav-link"
                            data-toggle="collapse"
                            data-target="#collapsingNavbar"
                            aria-controls="collapsingNavbar"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                            href="#header"
                          >
                            BUY AN ODD FELLOW{" "}
                          </AnchorLink>
                        </li>
                        <li className="nav-item">
                          <AnchorLink
                            className="nav-link"
                            data-toggle="collapse"
                            data-target="#collapsingNavbar"
                            aria-controls="collapsingNavbar"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                            href="#roadmap"
                          >
                            ROADMAP{" "}
                          </AnchorLink>
                        </li>
                        <li className="nav-item">
                          <AnchorLink
                            className="nav-link"
                            data-toggle="collapse"
                            data-target="#collapsingNavbar"
                            aria-controls="collapsingNavbar"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                            href="#team"
                          >
                            TEAM{" "}
                          </AnchorLink>
                        </li>
                        <li className="nav-item">
                          <a
                            href={`${config.baseUrl}marketplace`}
                            className="nav-link "
                          >
                            GALLERY{" "}
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            href={`${config.baseUrl}member`}
                            className="nav-link "
                          >
                            MEMBERS{" "}
                          </a>
                        </li>
                      </ul>
                    </MobileView>
                    <BrowserView>
                      <ul className="navbar-nav mr-auto pt-md-1 ">
                        <li className="nav-item">
                          <a className="nav-link" href="https://rarible.com/thesecretsocietyofoddfellows/items
" target="_blank">
                            BUY AN ODD FELLOW{" "}
                          </a>
                        </li>
                        &nbsp;
                        <li className="nav-item">
                          <a className="nav-link" href="#roadmap">
                            ROADMAP{" "}
                          </a>
                        </li>
                        &nbsp;
                        <li className="nav-item">
                          <a className="nav-link" href="#team">
                            TEAM{" "}
                          </a>
                        </li>
                        &nbsp;
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            href={`${config.baseUrl}marketplace`}
                          >
                            GALLERY{" "}
                          </a>
                        </li>
                        &nbsp;
                        <li className="nav-item">
                          <a
                            href={`${config.baseUrl}member`}
                            className="nav-link "
                          >
                            MEMBERS{" "}
                          </a>
                        </li>
                      </ul>
                    </BrowserView>

                    <ul className="navbar-nav ml-auto pb-md-1">
                      <li>
                        <div className="social-buttons lead-1">
                          <a
                            className="social-facebook"
                            target="_blank"
                            href="https://discord.com/invite/oddfellowsnft"
                          >
                            <img
                              src="assets/img/Discord-Logo.png"
                              width="25px"
                              style={{
                                filter: "brightness(0) invert(1)",
                                "object-fit": "contain",
                              }}
                            />
                          </a>
                          <a
                            className="social-twitter"
                            target="_blank"
                            href="https://twitter.com/oddfellowsnft"
                          >
                            <i className="fab fa-twitter" />
                          </a>
                          <a
                            className="social-instagram"
                            target="_blank"
                            href="https://www.instagram.com/oddfellowsnft/"
                          >
                            <i className="fab fa-instagram" />
                          </a>
                          <a
                            className="social-dribbble"
                            target="_blank"
                            href="https://rarible.com/thesecretsocietyofoddfellows/items
"
                            style={{
                              background: "yellow",
                              borderRadius: "5px",
                            }}
                          >
                            <svg
                              width="19"
                              height="14"
                              viewBox="0 0 19 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M18.7927 3.74115C18.7927 5.56401 17.7277 6.47067 16.5308 6.78561C17.9633 7.21508 19 8.38897 19 10.25V13.6667H13.5337V10.4218C13.5337 9.42924 12.9494 9.0284 11.9504 9.0284H5.46627V13.6667H0V0H12.9871C16.248 0 18.7927 0.706239 18.7927 3.74115ZM5.46871 3.81832H12.8585V3.81891C12.8695 3.81852 12.8806 3.81832 12.8917 3.81832C13.3998 3.81832 13.8118 4.23545 13.8118 4.75C13.8118 5.26455 13.3998 5.68168 12.8917 5.68168C12.8806 5.68168 12.8695 5.68148 12.8585 5.68109V5.68168H5.46871V3.81832Z"
                                fill="black"
                              ></path>
                            </svg>
                            {/* <i className="fab fa-opensea"><img src="assets/img/ship.png" style={{ width: '21px', marginTop: '-3px' }} /></i> */}
                          </a>
                        </div>
                      </li>
                      <li
                        className="nav-item"
                        id={
                          !localStorage.getItem("connectWalletAddress")
                            ? ""
                            : "connecting_wallet"
                        }
                      >
                        {!localStorage.getItem("connectWalletAddress") ? (
                          <>
                            <BrowserView>
                              <button
                                className="nav-link btn-border"
                                style={{ cursor: "pointer" }}
                                data-toggle="modal"
                                data-target="#connect_wallet_modal"
                                onClick={cwalletClick}
                              >
                                Connect Wallet
                              </button>
                            </BrowserView>

                            <MobileView>
                              <button
                                className="nav-link btn-border"
                                data-toggle="collapse"
                                data-target="#collapsingNavbar"
                                aria-controls="collapsingNavbar"
                                aria-expanded="false"
                                aria-label="Toggle navigation"
                                type="submit"
                                style={{ cursor: "pointer" }}
                                onClick={openMetamask}
                              >
                                Connect Wallet
                              </button>
                            </MobileView>
                          </>
                        ) : (
                          <>
                            <button
                              className="nav-link btn-border"
                              title={localStorage.getItem(
                                "connectWalletAddress"
                              )}
                            >
                              {localStorage
                                .getItem("connectWalletAddress")
                                .toString()
                                .substring(0, 5) +
                                "..." +
                                localStorage
                                  .getItem("connectWalletAddress")
                                  .toString()
                                  .substr(
                                    localStorage.getItem("connectWalletAddress")
                                      .length - 5
                                  )}
                            </button>
                            <button
                              title="Disconnect Wallet"
                              className="nav-link btn-border"
                              style={{ cursor: "pointer" }}
                              onClick={(e) => accountLogout(e)}
                            >
                              <i className="fas fa-sign-out-alt"></i>
                            </button>
                          </>
                        )}
                      </li>

                      <li classname="nav-item">
                        {localStorage.getItem("connectWalletAddress") ? (
                          <>
                            <button
                              className="wallet-box"
                              onClick={() =>
                                setState({ isPaneOpen: !state.isPaneOpen })
                              }
                            >
                              <img
                                className="wallet_icon"
                                src="assets/img/wallet-i.png"
                              />
                            </button>
                          </>
                        ) : (
                          ""
                        )}

                        <SlidingPane
                          className="some-custom-class"
                          overlayClassName="some-custom-overlay-class"
                          isOpen={state.isPaneOpen}
                          width="30%"
                          onRequestClose={() => {
                            setState({ isPaneOpen: false });
                          }}
                        >
                          <RewardMenu />
                        </SlidingPane>
                      </li>
                    </ul>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="connect_wallet_modal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="connect_walletModal3Label"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="connect_walletModal3Label">
                <b>Connect Wallet</b>
              </h5>
              <button
                type="button"
                className="close closeModal"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="connect_wallet_panel">
                {ismetamaskconnect === false && iscoinbaseconnect === false ? (
                  <div class="row">
                    <div className="col-sm-6 col-6">
                      <div
                        className="connect_wallet_div"
                        onClick={(e) => connectMetaMask(e)}
                      >
                        <img src="assets/img/metamask.svg" alt="Icon" />
                        <p style={{ color: "#fff" }} className="mb-0">
                          Metamask Wallet
                        </p>
                      </div>
                    </div>
                    <div className="col-sm-6 col-6">
                      <div
                        className="connect_wallet_div"
                        onClick={(e) => connectMetaMask1(e)}
                      >
                        <img src="assets/img/coinbas.webp" alt="Icon" />
                        <p style={{ color: "#fff" }} className="mb-0">
                          Coinbase Wallet
                        </p>
                      </div>
                    </div>
                  </div>
                ) : ismetamaskconnect === true ? (
                  <div class="row">
                    <div className="col-sm-12 col-12">
                      <img
                        src="assets/img/metamask.svg"
                        alt="Icon"
                        style={{ height: "40px", width: "40px" }}
                      />
                      &nbsp;&nbsp;
                      <span style={{ color: "#fff" }} className="mb-0">
                        MetaMask Wallet
                      </span>
                      <br />
                      <p style={{ color: "#fff" }} className="mb-0">
                        {" "}
                        You'll need to install MetaMask to continue. Once you
                        have it installed, go ahead.{" "}
                      </p>
                    </div>

                    <div
                      className="col-sm-6 col-6"
                      style={{ marginTop: "5px" }}
                    >
                      <a
                        href="javascript:void(0)"
                        type="submit"
                        onClick={setDefault}
                      >
                        Back
                      </a>
                    </div>

                    <div className="col-sm-6 col-6">
                      <a
                        href="https://metamask.io/download"
                        target="_blank"
                        className="coinbase-web"
                      >
                        Open Metamask Wallet
                      </a>
                    </div>
                  </div>
                ) : iscoinbaseconnect === true ? (
                  <div class="row">
                    <div className="col-sm-12 col-12">
                      <img
                        src="assets/img/coinbas.webp"
                        style={{ height: "40px", width: "40px" }}
                        alt="Icon"
                      />
                      &nbsp;&nbsp;
                      <span style={{ color: "#fff" }} className="mb-0">
                        Coinbase Wallet
                      </span>
                      <br />
                      <p style={{ color: "#fff" }} className="mb-0">
                        {" "}
                        You will need to install Coinbase Wallet to continue.
                        Click below to install.{" "}
                      </p>
                    </div>

                    <div
                      className="col-sm-6 col-6"
                      style={{ marginTop: "5px" }}
                    >
                      <a
                        href="javascript:void(0)"
                        type="submit"
                        onClick={setDefault}
                      >
                        Back
                      </a>
                    </div>

                    <div className="col-sm-6 col-6">
                      <a
                        href="https://www.coinbase.com/wallet"
                        target="_blank"
                        className="coinbase-web"
                      >
                        Open Coinbase Wallet
                      </a>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
