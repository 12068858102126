import React, { Component } from 'react';
import Header from '../directives/header';
import Footer from '../directives/footer';

const member = (props) => {


    return (

        <>
            <Header />
            <main className="main-content">

                <section id className="characters content-section mt-5 latest-articles text-light">
                    <section className="banner_section_member"> 
                        <div className="container text-left">
                            {/* <h2>Members</h2> */}
                        </div>
                    </section>
                    <br />
                    <div className="content-section text-light pt-0">
                        <div className="container">
                            <div className="row gutters-y team-member-box justify-content-center">
                                <div className='col-lg-12 pb-0 text-left'>
                                    
                                    <h2 class="ls-1 italicfont"> MARYLAND</h2>
                                    <hr class="w-10 pull-left mt-0 border-warning border-top-2 o-90"></hr>

                                </div>


                                <div className="col-lg-6">
                                    <div className="row">
                                        <div className="col-12 pl-0">
                                            <div className>

                                                <div className="carousel-product">
                                                    <div className="slider text-secondary" data-slick="product-body">
                                                        <img src="assets/img/member-1.jpg" alt="Game" className='member_1' />
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">

                                    <div className="col-sm-12 pl-0">
                                        <div className=" readmore_content">
                                            <p>Enjoy FREE VIP Access, Table Service and Bottle Service including a FREE Bottle of Liquor at the Penthouse Gentlemen’s Club Baltimore for you and 4 of your closest friends (must be 21 and older to enter club and must hold Odd Fellow in your MetaMask or Coinbase Wallet).
                                                Upon arrival at the Penthouse Gentlemen's Club Baltimore, you will be treated like an absolute king or queen. You will receive any bottle priced $275 or below and a lifetime of FREE admission for you and up to 4 guests at ALL Penthouse Club locations.&nbsp;&nbsp;<a target="_blank" href="https://penthouseclubbaltimore.com/" className='btn btn-sm btn-border text-white pt-1 pb-1' style={{ height: "28px" }}>Visit Website</a></p>
                                        </div>

                                    </div>
                                </div>
                                <div className='col-sm-12'>
                                {/* <hr id="one" data-symbol=""/> */}
                                <div class="divider"><span></span><span>*****</span><span></span></div>
                                    
                                </div>
                            </div>
                            <div className="row gutters-y mt-5 team-member-box justify-content-center">
                                <div className='col-lg-12 pb-0 text-left'>
                                    <h2 class="ls-1 italicfont"> NEW JERSEY</h2>
                                    <hr class="w-10 pull-left mt-0 border-warning border-top-2 o-90"></hr>

                                </div>
                                <div className="col-lg-6 desktop-hide">
                                    <div className="row">
                                        <div className="col-12 pl-0">
                                            <div className>

                                                <div className="carousel-product">
                                                    <div className="slider text-secondary" data-slick="product-body">
                                                        <img src="assets/img/member-2.jpg" className='member_1' alt="Game" />
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 ">

                                    <div className="col-sm-12 pl-0">
                                        <div className=" readmore_content">
                                            <p>Enjoy a FREE Stack of Pancakes at the IHOP on 1120 US Hwy 22 in N. Plainfield, NJ 07060. Upon arrival at the IHOP at 1120 US Hwy 22 in N. Plainfield, NJ, you will be treated like an absolute king or queen. You will receive a FREE Stack of Pancakes (5) for you to enjoy. (Must hold Odd Fellow in your MetaMask or Coinbase Wallet).&nbsp;&nbsp;<a target="_blank" href="https://restaurants.ihop.com/en-us/nj/n-plainfield/breakfast-1120-us-hwy-22-4692?utm_source=google&utm_medium=organic&utm_campaign=google_my_business&utm_term=4692&utm_content=website" className='btn btn-sm btn-border text-white pt-1 pb-1' style={{ height: "28px" }}>Visit Website</a></p>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-lg-6 mobile-hide">
                                    <div className="row">
                                        <div className="col-12 pl-0">
                                            <div className>

                                                <div className="carousel-product">
                                                    <div className="slider text-secondary" data-slick="product-body">
                                                        <img src="assets/img/member-2.jpg" className='member_1' alt="Game" />
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-sm-12'>
                               
                                <div class="divider"><span></span><span>*****</span><span></span></div>
                                    
                                </div>

                            </div>
                            <div className="row gutters-y team-member-box justify-content-center">
                                <div className='col-lg-12  pb-0 text-left'>
                                    <h2 class="ls-1 italicfont"> NEW YORK</h2>
                                    <hr class="w-10 pull-left mt-0 border-warning border-top-2 o-90"></hr>

                                </div>


                                <div className="col-lg-6">
                                    <div className="row">
                                        <div className="col-12 pl-0">
                                            <div className>

                                                <div className="carousel-product pt-4">
                                                    <div className="slider text-secondary" data-slick="product-body">
                                                        <img src="assets/img/new-york1.jpg" alt="Game" className='member_1' />
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 ">

                                    <div className="col-sm-12 pl-0">
                                        <div className=" readmore_content">
                                            <p>Enjoy a FREE Round of Golf at the Saratoga Lake Golf Club and a FREE Appetizer at The Hideaway! A par 72 public golf course in the heart of a lush forest, just minutes from downtown Saratoga Springs, NY. One of the most relaxing golf courses in upstate New York, every round takes players past sparkling ponds, beautiful wetlands and views of Saratoga Lake. Furthermore the pristine feel, complete with thick strands of pines and hardwoods gives players the feeling like they’re golfing in the middle of a national forest. This is truly a must-play golf course in Saratoga Springs, NY.&nbsp;&nbsp;<a target="_blank" href="http://www.penthouseclubbaltimore.com/" className='btn btn-sm btn-border text-white pt-1 pb-1' style={{ height: "28px" }}>Visit Website</a></p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="row gutters-y mt-5 team-member-box justify-content-center">
                                
                            <div className="col-lg-6 desktop-hide">
                                    <div className="row">
                                        <div className="col-12 pl-0">
                                            <div className>

                                                <div className="carousel-product pt-4">
                                                    <div className="slider text-secondary" data-slick="product-body">
                                                        <img src="assets/img/new-york2.jpg" className='member_1' alt="Game" />
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 pl-0">

                                    <div className="col-sm-12 ">
                                        <div className=" readmore_content">
                                            <p>Enjoy a FREE Appetizer at one of the newest restaurants to enter into the Saratoga Springs community. The Hideaway has indoor and outdoor seating, with the inside offering casual but elegant accommodations and the outside scenic views of the 18-hole golf course, the Adirondacks, and Saratoga Lake. The Hideaway features an extensive menu with entrees like Surf & Turf, Lobster Ravioli & Sirloin & Mushroom Risotto; a variety of sandwiches from their signature Fried Chicken Sandwich to The Reuben; and several starters, side dishes, salads, and more. Vegetarian and gluten-free options are available. There's something for everyone on our menu.&nbsp;&nbsp;<a target="_blank" href="https://www.hideawaysaratoga.com/" className='btn btn-sm btn-border text-white pt-1 pb-1' style={{ height: "28px" }}>Visit Website</a></p>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-lg-6 mobile-hide">
                                    <div className="row">
                                        <div className="col-12 pl-0">
                                            <div className>

                                                <div className="carousel-product pt-4">
                                                    <div className="slider text-secondary" data-slick="product-body">
                                                        <img src="assets/img/new-york2.jpg" className='member_1' alt="Game" />
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>

            </main>



            <Footer />
        </>
    )
}

export default member;