// import React, { Component } from 'react';


import React, { useEffect, useState } from 'react';
import Header from '../directives/header';
import Footer from '../directives/footer';
import config from '../config/config';
import { NavLink, useHistory } from 'react-router-dom';
import Countdown, { zeroPad } from 'react-countdown';
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';
import Web3 from 'web3';
import Swal from 'sweetalert2'
import { BrowserView, MobileView } from 'react-device-detect';
import Onboard from 'bnc-onboard'

let web3;

const onboard = Onboard({
  dappId: "a830de3a-cbe0-4fc2-9242-5c9c406f6fb2",
  networkId: 0x1,
  subscriptions: {
    wallet: wallet => {
      web3 = new Web3(wallet.provider)
    }
  },

  walletSelect: {
    wallets: [
      {
        walletName: 'metamask'
      },
      {
        walletName: 'coinbase'
      },
    ]
  }

});



function Home() {
  const [purchased_quantity, setpurchased_quantity] = useState(1)
  const [errorAvailable, seterrorAvailable] = useState('')
  const [form, setForm] = useState({ purchased_quantity: 0 })
  const [content1, setContent1] = useState([])
  const [content2, setContent2] = useState([])
  const [content3, setContent3] = useState([])
  const [content4, setContent4] = useState([])
  const [content5, setContent5] = useState([])
  const [content6, setContent6] = useState([])
  const [content7, setContent7] = useState([])
  const [content8, setContent8] = useState([])
  const [MintText, setMintText] = useState('Mint');
  const [getTimesFromDB, setGetTimesFromDB] = useState('');
  const [mintStarted, setmintStarted] = useState(true);
  const [siteLoad, setsiteLoad] = useState(false);
  const [TotalMined, setTotalMined] = useState(0);
  const [mintLimit, setMintLimit] = useState(888);

  const [iscoinbaseconnect, setiscoinbaseconnect] = useState(false)
  const [isConnectCoinbase, setisConnectCoinbase] = useState(false);
  const [ismetamaskconnect, setismetamaskconnect] = useState(false)
  const [connectWalletAddress, setConnectWalletAddress] = useState('');
  const [isConnectMetamask, setisConnectMetamask] = useState(false);


  useEffect(async () => {
    getSection1API()
    getSection2API()
    getSection3API()
    getSection4API()
    getSection5API()
    getSection6API()
    getSection7API()
    getSection8API()
    getTimesFromDBAPI()
    getContractDetails()
    const { ethereum } = window;
    if (ethereum) {

      ethereum.on('accountsChanged', (add) =>
        manageWallet(add)
      );
      ethereum.on('networkChanged', (chainId) =>
        window.location.reload()
      );
    }

  }, [])




  const cwalletClick = async () => {
    setismetamaskconnect(false)
    // setismetamaskconnect1(false)

    setiscoinbaseconnect(false)
  }

  async function openMetamask() {


    await onboard.walletSelect();
    connectMetaMask()
    connectMetaMask1()
  }

  const connectMetaMask = async () => {
    if (window.ethereum) {
      var isMetaMask = await window.ethereum.isMetaMask;
      if (!isMetaMask) {
        setismetamaskconnect(true)
        // toast.error(`Please install Metamask wallet to use this App!`);
        return;
      }

      if (!window.ethereum.providers) {
        var metamaskProvider = await window.ethereum;
      } else {
        var metamaskProvider = await window.ethereum.providers.find((provider) => provider.isMetaMask);
      }
      try {
        const accounts = await metamaskProvider.request({ method: 'eth_requestAccounts' });
        setConnectWalletAddress(accounts[0])
        setisConnectMetamask(true)
        localStorage.setItem('walletType', 'metamask')
        localStorage.setItem('connectWalletAddress', accounts[0]);
        localStorage.setItem('isLogout', false);
        window.location.reload();
      } catch (err) {
        Swal.fire({
          text: err.message,
          // icon: 'warning',
          title: "Alert",
          confirmButtonText: 'Okay',
          confirmButtonColor: '#ae7d5c',
          background: '#311a12',
          color: '#fff'
        })
      }

    }
    else {
      setismetamaskconnect(true)
      // toast.error(`Please install MetaMask to use this App!`, {

      // });
    }
  }

  const connectMetaMask1 = async () => {
    if (window.ethereum) {

      if (!window.ethereum.providers) {
        if (window.ethereum.isCoinbaseWallet == true) {
          var coinbaseProvider = await window.ethereum;
        } else {
          // alert('44422223333')

          setiscoinbaseconnect(true)
          // toast.error(`Please install Coinbase wallet to use this App!`);
          return;
        }
      } else {

        var coinbaseProvider = await window.ethereum.providers.find((provider) => provider.isCoinbaseWallet);
      }

      try {

        const accounts = await coinbaseProvider.request({ method: 'eth_requestAccounts' });
        setConnectWalletAddress(accounts[0])
        localStorage.setItem('walletType', 'coinbase')
        localStorage.setItem('connectWalletAddress', accounts[0])
        localStorage.setItem('isLogout', false);
        window.location.reload();
        setisConnectCoinbase(true);
      } catch (err) {
        Swal.fire({
          text: err.message,
          // icon: 'warning',
          title: "Alert",
          confirmButtonText: 'Okay',
          confirmButtonColor: '#ae7d5c',
          background: '#311a12',
          color: '#fff'
        })
      }

    }
    else {
      // alert('4442222wqewq')

      setiscoinbaseconnect(true)
      // toast.error(`Please install Coinbase to use this App!`, {

      // });
    }
  }

  async function manageWallet(address) {
    let cc = await localStorage.getItem("connectWalletAddress").toString();
    let add = await address.toString();

    if (cc.toUpperCase() != add.toUpperCase()) {
      localStorage.setItem("connectWalletAddress", address);
      window.location.reload();
    }
  }

  // getTimesFromDBAPI
  const getTimesFromDBAPI = async () => {
    await axios({
      method: 'get',
      url: `${config.apiUrl}getStartTime`,
    }).then(res => {

      if (res.data.response[0].timeStart) {
        setGetTimesFromDB(res.data.response[0].date_year)
      } else {
        setGetTimesFromDB('00:00:00');
        setmintStarted(true);
        console.log("Yeah!!!!")
      }
      setsiteLoad(true);

    })
  }
  //==========================================  GET Section1  ================================  

  const getSection1API = async () => {
    await axios({
      method: 'get',
      url: `${config.apiUrl}getSection1`,
    }).then(response => {
      if (response.data.success === true) {
        setContent1(response)
      }
    })
  }

  //==========================================  GET Section2  ================================  

  const getSection2API = async () => {
    await axios({
      method: 'get',
      url: `${config.apiUrl}getSection2`,
    }).then(response => {
      if (response.data.success === true) {
        setContent2(response)
      }
    })
  }

  //==========================================  GET Section3  ================================  

  const getSection3API = async () => {
    await axios({
      method: 'get',
      url: `${config.apiUrl}getSection3`,
    }).then(response => {
      if (response.data.success === true) {
        setContent3(response)
      }
    })
  }

  //==========================================  GET Section4  ================================  

  const getSection4API = async () => {
    await axios({
      method: 'get',
      url: `${config.apiUrl}getSection4`,
    }).then(response => {
      if (response.data.success === true) {
        setContent4(response)
      }
    })
  }

  //==========================================  GET Section5  ================================  

  const getSection5API = async () => {
    await axios({
      method: 'get',
      url: `${config.apiUrl}getSection5`,
    }).then(response => {
      if (response.data.success === true) {
        setContent5(response)
      }
    })
  }

  //==========================================  GET Section6  ================================  

  const getSection6API = async () => {
    await axios({
      method: 'get',
      url: `${config.apiUrl}getSection6`,
    }).then(response => {
      if (response.data.success === true) {
        setContent6(response)
      }
    })
  }

  //==========================================  GET Section7  ================================  

  const getSection7API = async () => {
    await axios({
      method: 'get',
      url: `${config.apiUrl}getRoadmap`,
    }).then(response => {
      if (response.data.success === true) {
        setContent7(response)
      }
    })
  }

  //==========================================  GET Section8  ================================  

  const getSection8API = async () => {
    await axios({
      method: 'get',
      url: `${config.apiUrl}getteam`,
    }).then(response => {
      if (response.data.success === true) {
        setContent8(response.data.response)
      }
    })
  }



  const getTimeOfStartDate = (dateTime) => {

    // var date = new Date(dateTime); // some mock date
    // var milliseconds = date.getTime();
    var countDownDate = Date.parse(dateTime);

    return countDownDate;
  }

  const CountdownTimer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      setmintStarted(true);
      // Render a completed state
      return "Pre-sale is started...!!!";
    } else {
      getTimesFromDBAPI();
      // Render a countdowns
      if (getTimesFromDB) {
        let newTime = getTimesFromDB.split(':');
        hours = newTime[0];
        minutes = newTime[1];
        seconds = newTime[2];
      }
      return (
        <>
          <li><span id="days" >{days || 0}</span>Days</li>
          <li><span id="hours" >{zeroPad(hours)}</span>Hours</li>
          <li><span id="minutes" >{zeroPad(minutes)}</span>Minutes</li>
          <li><span id="seconds" >{zeroPad(seconds)}</span>Seconds</li>
        </>
        // <span>{dayPrint} {zeroPad(hours)}h {zeroPad(minutes)}m {zeroPad(seconds)}s</span>
      );
    }
  };


  const minusQuantity = () => {
    var qty = parseInt(purchased_quantity) - parseInt(1)
    if (purchased_quantity > 1) {
      setpurchased_quantity(qty)
      // this.setState({
      //    'purchased_quantity': qty
      // })
    }

    // if (qty > responsedata.available_quantity) {
    //    seterrorAvailable(1)


    // }
    else if (qty === '0' || qty === '') {
      seterrorAvailable(2)
    }
    else {
      seterrorAvailable(0)
    }
  }


  const plusQuantity = () => {
    var qty = parseInt(purchased_quantity) + parseInt(1);
    if (qty > 5) {
      return;
    }
    setpurchased_quantity(qty)


    if (qty === '0' || qty === '') {
      seterrorAvailable('2')
    }
    else {
      seterrorAvailable('0')
    }

  }

  const handleChange = async (e) => {
    const { name, value } = e.target
    setForm((old) => {
      return { ...old, [name]: value }
    })

    if (e.target.name === 'purchased_quantity') {

      //  if (e.target.value > responsedata.available_quantity) {
      //     seterrorAvailable('1')


      //  }
      if (e.target.value === '0' || e.target.value === '') {
        seterrorAvailable('2')

      }
      //  else {
      //     seterrorAvailable('0')
      //  }
    }
  }

  const getCurrentProvider = async () => {
    let CurrentProvider = "";
    if (localStorage.getItem("connectWalletAddress") && localStorage.getItem("walletType") == 'metamask') {
      if (!window.ethereum.providers) {
        CurrentProvider = await window.ethereum;
      } else {
        CurrentProvider = await window.ethereum.providers.find((provider) => provider.isMetaMask);
      }
    } else {
      if (!window.ethereum.providers) {
        if (window.ethereum.isCoinbaseWallet == true) {
          CurrentProvider = await window.ethereum;
        }
      } else {
        CurrentProvider = await window.ethereum.providers.find((provider) => provider.isCoinbaseWallet);
      }
    }
    return CurrentProvider;
  }

  const mintItem = async (e) => {

    if (MintText === "Processing...") {
      return;
    }

    let nftNumber = parseInt(purchased_quantity);
    if (nftNumber > 5) {
      Swal.fire({
        text: '5 NFTs mint in per transaction',
        // icon: 'warning',
        title: "Alert",
        confirmButtonText: 'Okay',
        confirmButtonColor: '#ae7d5c',
        background: '#311a12',
        color: '#fff'
      })
      return;
    }
    if (parseInt(TotalMined) + nftNumber > parseInt(mintLimit)) {
      let mint = parseInt(mintLimit) - parseInt(TotalMined);
      Swal.fire({
        text: 'Only ' + mint + ' slot left for mint',
        // icon: 'warning',
        title: "Alert",
        confirmButtonText: 'Okay',
        confirmButtonColor: '#ae7d5c',
        background: '#311a12',
        color: '#fff'
      })
      return;
    }
    if (!localStorage.getItem("connectWalletAddress")) {
      Swal.fire({
        text: 'Please connect your wallet!',
        // icon: 'warning',
        title: "Alert",
        confirmButtonText: 'Okay',
        confirmButtonColor: '#ae7d5c',
        background: '#311a12',
        color: '#fff'
      })
      return;
    }
    const providers = await getCurrentProvider();
    const web3 = new Web3(providers);

    try {
      web3.eth.defaultAccount = localStorage.getItem("connectWalletAddress");


      if (web3.currentProvider.networkVersion != 1 && web3.currentProvider.networkVersion != '0x1') {

        Swal.fire({
          text: 'Please select Ethereum network!',
          // icon: 'warning',
          title: "Alert",
          confirmButtonText: 'Okay',
          confirmButtonColor: '#ae7d5c',
          background: '#311a12',
          color: '#fff'
        })
        return;
      }
      setMintText("Processing...");
      const NFT_CONTRACT = await new web3.eth.Contract(config.ABI, config.contractAddress);
      let from_address = localStorage.getItem("connectWalletAddress");
      // let balance = await web3.eth.getBalance(from_address);

      let chainId = '0x1';


      let getMintFees = await NFT_CONTRACT.methods.getMintFees(nftNumber).call();
      getMintFees = parseInt(getMintFees);

      let myAddress = from_address.toUpperCase();

      let contractOnwer = await NFT_CONTRACT.methods.owner().call();
      let collaborator = await NFT_CONTRACT.methods.collaborator().call();
      let encoded_tx = "";
      if (collaborator.toUpperCase() == myAddress || myAddress == contractOnwer.toUpperCase()) {
        let tx_builder = await NFT_CONTRACT.methods.mintAsOwner(nftNumber);
        encoded_tx = tx_builder.encodeABI();
        getMintFees = 0;
      } else {
        let tx_builder = await NFT_CONTRACT.methods.mint(nftNumber);
        encoded_tx = tx_builder.encodeABI();
      }




      let gasPrice = await web3.eth.getGasPrice();
      gasPrice = parseInt(gasPrice);

      let gasLimit = await web3.eth.estimateGas({
        gasPrice: web3.utils.toHex(gasPrice),
        to: config.contractAddress,
        from: from_address,
        value: web3.utils.toHex(getMintFees),
        chainId: chainId,
        data: encoded_tx
      });
      // let totalGasFee = parseInt(gasPrice)*parseInt(gasLimit)+getMintFees;
      // if(parseInt(totalGasFee) > parseInt(balance)){
      //   toast.error("P12231213lease select Ethereum network!");
      //   return;
      // }

      const transactionParameters = {
        from: from_address,
        gasPrice: web3.utils.toHex(gasPrice),
        gas: web3.utils.toHex(gasLimit),
        to: config.contractAddress,
        value: web3.utils.toHex(getMintFees),
        chainId: chainId,
        data: encoded_tx
      };

      const txHash = await web3.eth.sendTransaction(transactionParameters);
      // let messageAlert = `<span>Transaction details : <a style="color:blue" href="https://etherscan.io/tx/${txHash.transactionHash}" target="_blank">0x....hash</a> </span>`;
      // messageAlert = ` ${messageAlert} <br/> <a style="color:blue" href="https://testnets.opensea.io/${localStorage.getItem("connectWalletAddress")}" target="_blank">View on Opensea</a> </span>`

      if (txHash.transactionHash) {
        Swal.fire({
          width: 500,
          title: 'Successful!',
          html: 'Congratulation! NFT minted Successfully.',
          // icon: 'warning',
          title: "Alert",
          confirmButtonText: 'Ok, Thanks',
          confirmButtonColor: '#ae7d5c',
          background: '#311a12',
          color: '#fff'
        }).then((result) => {
          window.location.reload();
        })
      }
    } catch (err) {
      console.log("err", err)
      setMintText("Mint");

      if (err.message.toString().split('insufficient funds')[1]) {

        Swal.fire({
          text: 'Transaction reverted : ' + err.message,
          // icon: 'warning',
          title: "Alert",
          confirmButtonText: 'Okay',
          confirmButtonColor: '#ae7d5c',
          background: '#311a12',
          color: '#fff'
        })
      } else {
        if (err.toString().split('execution reverted:')[1]) {
          Swal.fire({
            text: 'Transaction reverted : ' + (err.toString().split('execution reverted:')[1]).toString().split('{')[0],
            // icon: 'warning',
            title: "Alert",
            confirmButtonText: 'Okay',
            confirmButtonColor: '#ae7d5c',
            background: '#311a12',
            color: '#fff'
          })
        } else {
          if (err.message.toString().search(/supplied gas/) > -1 || err.message.toString().search(/exceeds allowance/) > -1) {
            Swal.fire({
              text: "Transaction reverted : insufficient funds for transaction fee",
              // icon: 'warning',
              title: "Alert",
              confirmButtonText: 'Okay',
              confirmButtonColor: '#ae7d5c',
              background: '#311a12',
              color: '#fff'
            })
          } else {
            Swal.fire({
              text: err.message,
              // icon: 'warning',
              title: "Alert",
              confirmButtonText: 'Okay',
              confirmButtonColor: '#ae7d5c',
              background: '#311a12',
              color: '#fff'
            })
          }
        }
      }
    }

  }


  const getContractDetails = async () => {
    const web3Provider = new Web3.providers.HttpProvider('https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161');
    const web3 = new Web3(web3Provider);
    const NFT_CONTRACT = await new web3.eth.Contract(config.ABI, config.contractAddress);

    let totalMinted = await NFT_CONTRACT.methods.totalSupply().call();
    setTotalMined(totalMinted)


  }


  return (

    <>
      <Header />
      <header id="header" className="header h-fullscreen  text-light">

        <Toaster
          position="top-right"
          reverseOrder={false}
        />
        <div className="media-container parallax-window" data-overlay={7} data-parallax="scroll" data-image-src />
        <div className="overlay pe-n bg-dark_A-20" />
        <div className="overlay d-flex align-items-center">
          <div className="container text-center mt-8 pt-5">
            <div className="row align-items-center">
              <div className="col-lg-12 mx-auto banner">
                <div>

                  {(mintStarted == false && siteLoad == true) ?
                    <>
                      <h4>PRESALE JANUARY 13th, 7:00am EST</h4>
                      <div id="countdown" className="col-sm-5 col-9 ml-auto mr-auto">
                        <ul>
                          <Countdown
                            date={getTimeOfStartDate('2022-01-13T17:30:00')}
                            renderer={CountdownTimer}
                          />
                        </ul>
                      </div>
                    </> : ''}


                  <div className="row">



                    {mintStarted == true ? <>
                      {/* <div className="col-md-12 col-12">
                      <br/>
                      <h4>PUBLIC SALE IS NOW LIVE!</h4>
                      <h5>MINT YOUR ODD FELLOW NFT</h5>
                      <h6>{TotalMined} OF {mintLimit} SOLD</h6>
                    </div> */}

                      <div className="col-md-12 col-12 col-sm-12 row">
                        <div className="col-md-4 col-12"></div>
                        <div className="col-md-4 col-12">
                          <h2>SOLD OUT !</h2>
                          <a href='https://rarible.com/thesecretsocietyofoddfellows/items' target="_blank">
                          <div style={{paddingTop:'10px', border:'2px solid #f2f009',background:'#f2f009',borderRadius:'10px'}} >
                            <h6><a style={{color:'#333',fontWeight:'600'}}>BUY AN ODD FELLOW ON </a></h6>
                            <h6><a style={{color:'#333',fontWeight:'600'}}>RARIBLE</a></h6>
                          </div>
                          </a>
                          </div>
                      </div>

                      <div className="col-md-5 col-3">
                      </div>


                      {/* <div className="col-md-2 col-6" >
                        <div className="input-group mint-box d-flex mt-1">
                          <span className="input-group-btn" >
                            <button type="button" onClick={e => minusQuantity(e)} className="btn btn-sm btn-border text-white btn-number" data-type="minus" data-field="quant[2]">
                              <span className="fa fa-minus"></span>
                            </button>
                          </span>
                          <input type="text" onKeyPress={(event) => {
                            if (!/^\d*[]?\d{0,1}$/.test(event.key)) {
                              event.preventDefault();
                            }
                          }} className="form-control border-form" name="purchased_quantity" placeholder="Quantity" value={purchased_quantity}
                            onChange={e => handleChange(e)} style={{ fontSize: '12px', height: '37px', color: '#fff', background: 'transparent', fontWeight: '600', textAlign: 'center' }} />

                          <span className="input-group-btn">
                            <button type="button" onClick={e => plusQuantity(e)} className="btn btn-sm btn-border text-white btn-number" data-type="plus" data-field="quant[2]">
                              <span className="fa fa-plus"></span>
                            </button>
                          </span>
                        </div>
                        <div className="ml-auto me-auto mt-5">
                          {!localStorage.getItem('connectWalletAddress') ?
                            <>
                              <BrowserView>
                                <button className="btn text-white btn-border" style={{ cursor: 'pointer', width: 'unset' }} data-toggle="modal" data-target="#connect_wallet_modal" onClick={cwalletClick}>Mint</button>
                              </BrowserView>

                              <MobileView>
                                <button className="btn text-white btn-border" type='submit' style={{ cursor: 'pointer', width: 'unset' }} onClick={openMetamask}>Mint</button>
                              </MobileView></>
                            :
                            <button className="btn text-white btn-border" style={{ width: 'unset' }} onClick={e => mintItem(e)}>{MintText}</button>
                          }

                        </div>
                      </div> */}


                      {/* <div className="col-md-5 col-3">
                      </div>

                      <div className="col-md-12 col-12">
                        <br />
                        <span style={{ fontSize: '12px' }}>PUBLIC SALE 0.07 ETH</span>
                      </div> */}
                    </> : ''}

                  </div>
                  <h2 dangerouslySetInnerHTML={{ __html: content1.data?.response[0].heading }}></h2>
                  {/* <img src="assets/img/content/game-logo.png" alt="Game Logo"> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="fadeIn ad-800ms">
          <div className="down-arrow floating-arrow absolute-center-X">
            <span className="fas fa-chevron-down" />
          </div>
        </div>
      </header>
      <main className="main-content">
        <div className="partner-logo text-center">
          <div className="mobile-hide logo-1">From the Co-Founder of &nbsp;<img src={`${config.imageUrl}${content2.data?.response[0].image1}`} />&nbsp; in collaboration with <img src={`${config.imageUrl}${content2.data?.response[0].image2}`} />&nbsp; &amp; former <img src={`${config.imageUrl}${content2.data?.response[0].image3}`} /> and <img src={`${config.imageUrl}${content2.data?.response[0].image4}`} />&nbsp; #1 rapper, writer, and producer.</div>
          <div className=" mobile-logo logo-1">From the Co-Founder of <br />
            <img src={`${config.imageUrl}${content2.data?.response[0].image1}`} /><br />
            in collaboration with<br /> <img src={`${config.imageUrl}${content2.data?.response[0].image2}`} /><br />
            &amp; former #1 rapper, writer, and producer.<br /> <img src={`${config.imageUrl}${content2.data?.response[0].image3}`} />  <img src={`${config.imageUrl}${content2.data?.response[0].image4}`} /></div>
          {/* <div class="logo-1">
               Same Day Pros Home Service &nbsp;
                 <img src="assets/img/same-day-pros-home-services-logo.svg">
               </div>
               <div class="logo-1">
               Penthouse Club Baltimore &nbsp;
                 <img src="assets/img/penthouse-club-baltimore-logo.png">
               </div>
               <div class="logo-1">
               Roc-Fella Records &nbsp;
                 <img src="assets/img/rocafella.png">
               </div>
               <div class="logo-1">
               Billboard Music &nbsp;
                 <img src="assets/img/billboard.png">
               </div> */}
        </div>
        {/* Start Content Area */}
        <section id="about" className="content-section position-relative text-light text-center parallax-window" data-parallax="scroll" data-image-src="assets/img/1.webp" data-overlay={7}>
          <div className="container position-relative">
            <div className="row">
              <div className="col-sm-6 col-md-6 col-lg-8 mx-auto text-left">
                <div>
                  <h2 className="ls-1 italicfont" dangerouslySetInnerHTML={{ __html: content3.data?.response[0].heading }}></h2>
                  {/* <hr class="w-10 border-warning border-top-2 o-90"> */}
                  <p className="lead-1" dangerouslySetInnerHTML={{ __html: content3.data?.response[0].text }}></p>
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-4 mx-auto mb-7 md-lg-0">
                <div className="common-container">
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-6"><img className="img-fluid" src={`${config.imageUrl}${content3.data?.response[0].image1}`} aria-label="ape4" /></div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-6"><img className="img-fluid" src={`${config.imageUrl}${content3.data?.response[0].image2}`} alt="ape2" aria-label="ape2" /></div>
                  </div>
                  <div className="row pt-2">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-6"><img className="img-fluid" src={`${config.imageUrl}${content3.data?.response[0].image3}`} alt="ape3" aria-label="ape3" /></div>

                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-6"><img className="img-fluid" src={`${config.imageUrl}${content3.data?.response[0].image4}`} alt="ape1" aria-label="ape1" /></div>
                  </div>
                </div>
              </div>
              <div className="mb-5 mt-5 pt-5 col-lg-12 pl-0 pr-0">
                <div className="col">
                  <div id="buy-an-ape" className="buy-token-container">
                    <a href="https://discord.com/invite/oddfellowsnft" target="_blank" className="discord_btn">
                      <img src="assets/img/discord_btn.png" className="btn_discord"></img>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* /.End Content Area */}
        {/* Start Content Area */}
        <section id="characters" className="characters content-section latest-articles text-light" data-parallax="scroll" data-image-src="assets/img/1.webp" data-overlay={7}>
          <div className="container">
            <header className="section-header text-center">
              <h2 className="italicfont" style={{ position: 'relative' }} dangerouslySetInnerHTML={{ __html: content4.data?.response[0].heading }}></h2>
              <hr className="w-10 border-warning border-top-2 o-90" />
            </header>
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 mx-auto mb-7 md-lg-0">
                <p className="lead-1" dangerouslySetInnerHTML={{ __html: content4.data?.response[0].text }}></p>
              </div>
            </div>
            <div className="oschino ">
              <div className="row">
                <div className="col-sm-6 col-12 ">
                  <img src={`${config.imageUrl}${content4.data?.response[0].image1}`} className="oschino-img" />
                </div>
                <div className="col-sm-6 col-12 ">
                  <img src={`${config.imageUrl}${content4.data?.response[0].image2}`} className="oschino-img" />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* /.End Content Area */}
        {/* Start Content Area */}
        <section id="overview" className="content-section latest-articles  position-relative text-center text-light parallax-window" data-parallax="scroll" data-image-src style={{ backgroundColor: 'rgb(32 13 15)' }}>
          <div className="container position-relative">
            <div className="row">
              <div className="col-md-7  mb-md-0 text-left">
                <header className>
                  <h2 className="text-light italicfont" dangerouslySetInnerHTML={{ __html: content5.data?.response[0].heading }}></h2>
                  {/* <hr class="w-10 border-warning border-top-2 o-90"> */}
                </header>
                <p className="lead-1" dangerouslySetInnerHTML={{ __html: content5.data?.response[0].text }}></p>
              </div>
              <div className="col-md-4  mb-md-0">
                <img src={`${config.imageUrl}${content5.data?.response[0].image1}`} class="stateproperty" style={{ height: '400px' }} />
              </div>
            </div>
          </div>
        </section>
        <section id="contact" className="content-section club-section position-relative latest-articles text-light text-center parallax-window" data-parallax="scroll" data-image-src="assets/img/wallpaper.jpg" data-overlay={3}>
          <div className="container">
            <header className="section-header text-center">
              <h2 className="italicfont" dangerouslySetInnerHTML={{ __html: content6.data?.response[0].heading }}></h2>
              <hr className="w-10 border-warning border-top-2 o-90" />
              <p className="lead-1" dangerouslySetInnerHTML={{ __html: content6.data?.response[0].text }}></p>
            </header>
          </div>
        </section>
        {/* /.End Content Area */}
        {/* Start Content Area */}
        <section id="roadmap" className="content-section position-relative latest-articles text-light text-center parallax-window" data-parallax="scroll" data-image-src style={{ backgroundColor: 'rgb(32 13 15)' }}>
          <div className="container">
            <header className=" text-center mb-5">
              <h2 className="italicfont" dangerouslySetInnerHTML={{ __html: content7.data?.response[0].heading }}></h2>
              <hr className="w-10 border-warning border-top-2 o-90" />
              <p className="text-center" dangerouslySetInnerHTML={{ __html: content7.data?.response[0].text }}></p>
            </header>
            <div className="row">
              <div className="mb-3 mb-md-0 text-left mx-auto col-lg-12 col-12">
                <div className="mb-3 mb-lg-1 row">
                  <div className="m-auto col-lg-1 col-2 offset-lg-1 offset-1">
                    <p className="goal">{content7.data?.response[0].title1}</p>
                  </div>
                  <div className="m-auto col-lg-10 col-9">
                    <p className="text-decoration-line-through common-sub-p">{content7.data?.response[0].description1}</p>
                  </div>
                </div>
                <div className="mb-3 mb-lg-1 row">
                  <div className="m-auto col-lg-1 col-2 offset-lg-1 offset-1">
                    <p className="goal">{content7.data?.response[0].title2}</p>
                  </div>
                  <div className="m-auto col-lg-10 col-9">
                    <p className="text-decoration-line-through common-sub-p">{content7.data?.response[0].description2}</p>
                  </div>
                </div>
                <div className="mb-3 mb-lg-1 row">
                  <div className="m-auto col-lg-1 col-2 offset-lg-1 offset-1">
                    <p className="goal">{content7.data?.response[0].title3}</p>
                  </div>
                  <div className="m-auto col-lg-10 col-9">
                    <p className="text-decoration-line-through common-sub-p">{content7.data?.response[0].description3}</p>
                  </div>
                </div>
                <div className="mb-3 mb-lg-1 row">
                  <div className="m-auto col-lg-1 col-2 offset-lg-1 offset-1">
                    <p className="goal">{content7.data?.response[0].title4}</p>
                  </div>
                  <div className="m-auto col-lg-10 col-9">
                    <p className="text-decoration-line-through common-sub-p">{content7.data?.response[0].description4}</p>
                  </div>
                </div>
                <div className="mb-3 mb-lg-1 row">
                  <div className="m-auto col-lg-1 col-2 offset-lg-1 offset-1">
                    <p className="goal">{content7.data?.response[0].title5}</p>
                  </div>
                  <div className="m-auto col-lg-10 col-9">
                    <p className=" common-sub-p">{content7.data?.response[0].description5}</p>
                  </div>
                </div>
                {/* <div className="mb-3 mb-lg-1 row">
                  <div className="m-auto col-lg-1 col-2 offset-lg-1 offset-1">
                    <p className="goal">80%</p>
                  </div>
                  <div className="m-auto col-lg-10 col-9">
                    <p className="text-decoration-line-through common-sub-p">SSOF member-exclusive merch store gets unlocked, featuring limited edition tees, hoodies, and all kinds of other dope shit. To access the Merch Store, and future integrations, Odd Fellows will need to be signed into their MetaMask Wallet.</p>
                  </div>
                </div> */}
                <div className="mb-3 mb-lg-1 row">
                  <div className="m-auto col-lg-1 col-2 offset-lg-1 offset-1">
                    <p className="goal">{content7.data?.response[0].title6}</p>
                  </div>
                  <div className="m-auto col-lg-10 col-9">
                    <p class="text-decoration-line-through common-sub-p">{content7.data?.response[0].description6}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* /.End Content Area */}
        {/* Start Content Area */}
        <section id="team" className="content-section team-section latest-articles position-relative text-center text-light parallax-window" data-parallax="scroll" data-image-src="assets/img/team.jpg" data-overlay={7}>
          <div className="container position-relative">
            <header className="section-header">
              <h2 className="text-light italicfont">OUR TEAM</h2>
              <hr className="w-10 border-warning border-top-2 o-90" />
            </header>
            <div className="row">
              {content8.map(item => {
                return (<div className="col-md-4 col-12 mb-7 mb-md-0">
                  <a href="#">
                  </a><article className="card article-post bg-dark_A-40 border border-secondary py-6 px-5"><a href="#">
                    <div className="position-relative overflow-hidden br-n   article-image ar-1_1 w-70 mx-auto  border border-secondary mb-3" style={{ backgroundImage: `url(${config.imageUrl}${item.member_pic})`, backgroundSize: '100% 100%' }} />
                  </a><figure><a href="">
                  </a><figcaption><a href="#">
                  </a><h5 className="text-light mt-1 mb-0"><a href="#" /><a href="#" className="text-light"> {item.member}</a></h5>
                        <div className="social-buttons lead-1">
                          {item.facebook ?
                            <a className="social-facebook" href={item.facebook} target="_blank"><i className="fab fa-facebook" /></a> :
                            ''
                          }
                          {item.twitter ?
                            <a className="social-twitter" href={item.twitter} target="_blank"><i className="fab fa-twitter" /></a> : ''}
                          {item.instagram ?
                            <a className="social-instagram" href={item.instagram} target="_blank"><i className="fab fa-instagram" /></a> : ''}

                          {item.linkedin ?
                            <a className="social-dribbble" href={item.linkedin} target="_blank"><i className="fab fa-linkedin" /></a> : ''}

                          {item.spotify ?
                            <a class="social-instagram" href={item.spotify} target="_blank"><img src="assets/img/spotify.png" width="20px" style={{ marginTop: '-6px' }} /></a> : ''}

                          {item.music ?
                            <a class="social-instagram" href={item.music} target="_blank"><img src="assets/img/apple-music.png" width="20px" style={{ marginTop: '-6px' }} /></a> : ''}

                          {item.youtube ?
                            <a class="social-dribbble" target="_blank" href={item.youtube}><i class="fab fa-youtube"></i></a> : ''}
                        </div>
                        {/* <hr class="w-10 my-6 my-xl-7 border-warning"> */}
                        {/* <p class="lead-1">"Mauris tempus enim lorem, nec egestas est fringilla ut. Quisque iaculis dui egestas mi blandit viverra. Nulla luctus vulputate iaculis. Nulla facilisi."</p>
                              <span class="small text-warning">Marketing Ops</span> */}
                      </figcaption>
                    </figure>
                  </article>
                </div>
                )
              })}
            </div>
          </div>
        </section>
        {/* /.End Content Area */}
      </main>
      <Footer />
    </>
  );


}

export default Home;