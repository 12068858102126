


import React, { useEffect, useState } from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import config from '../config/config'
import Web3 from 'web3';
import Swal from 'sweetalert2'
import axios from 'axios'

const ABI = config.royaltyABI;
const Contract = config.royaltyContract;
const apiUrl = 'https://api.oddfellowsnft.com/api-royalty/';

const RewardMenu = (props) => {


    const [totalReward, settotalReward] = useState(0);
    const [isOnwer, setIsOnwer] = useState(false);
    const [RewardBtnText, setRewardBtnText] = useState('Withdraw');
    const [RoyaltyBtnText, setRoyaltyBtnText] = useState('Royalty Distribute');
    const [ETHinUSD, setETHinUSD] = useState(0);
    const [communityWalletBalance, setcommunityWalletBalance] = useState(0);

    useEffect(() => {
        getWalletDetail();
        // getRewardAmount();
        getCommunityWalletDetail();
        getLivePrice();
    }, [])

    const getLivePrice = async () => {
        await axios({
            method: 'get',
            url: `https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd`,

        }).then(response => {
            console.log(response)
            setETHinUSD(parseFloat(response.data.ethereum.usd).toFixed(4));
        })
    }

    const getWalletDetail = async () => {

        const providers = await getCurrentProvider();
        const web3 = new Web3(providers);
        let fromAddress = localStorage.getItem('connectWalletAddress');


        const response = await getClaimDetailsAPI(fromAddress);
        let myReward = parseFloat(response.amount);

        settotalReward(myReward);
        
        const Royalty_CONTRACT = await new web3.eth.Contract(ABI, Contract);
        let contractOnwer = await Royalty_CONTRACT.methods.owner().call();
        if (fromAddress.toUpperCase() == contractOnwer.toUpperCase()) {
            
            setIsOnwer(true);
        }
    }

    const getClaimDetailsAPI = async (address) => {
        const res = await axios({
            method: 'post',
            url: `${apiUrl}getclaimAmount`,
            data: { address }
        });
        return {
            status: res.data.status,
            amount: (!res.data.result.claimAmount) ? 0 : res.data.result.claimAmount,
            hexroot_id: (!res.data.result.hexroot_id) ? 0 : res.data.result.hexroot_id,
            hexproof: (!res.data.hexproof) ? [] : res.data.hexproof,
        }
    }


    const getCommunityWalletDetail = async () => {
        const providers = await getCurrentProvider();
        const web3 = new Web3(providers);
        let balance = await web3.eth.getBalance(Contract);

        balance = (parseFloat(parseInt(balance) / 10 ** 18) / 2).toFixed(6);
        setcommunityWalletBalance(!balance ? 0.00 : balance);
    }

    const getCurrentProvider = async () => {
        let CurrentProvider = "";
        if (localStorage.getItem('connectWalletAddress') && localStorage.getItem("walletType") == 'metamask') {
            if (!window.ethereum.providers) {
                CurrentProvider = await window.ethereum;
            } else {
                CurrentProvider = await window.ethereum.providers.find((provider) => provider.isMetaMask);
            }
        } else {
            if (!window.ethereum.providers) {
                if (window.ethereum.isCoinbaseWallet == true) {
                    CurrentProvider = await window.ethereum;
                }
            } else {
                CurrentProvider = await window.ethereum.providers.find((provider) => provider.isCoinbaseWallet);
            }
        }
        return CurrentProvider;
    }


    const claimReward = async () => {

        if (RewardBtnText === "Processing..." || totalReward == 0) {
            return;
        }

        // Swal.fire({
        //     text: 'Withdraw Functionality Will Open On February, 5th 2022',
        //     // icon: 'warning',
        //     title: "Notice!",
        //     confirmButtonText: 'Close',
        //     confirmButtonColor: '#ae7d5c',
        //     background: '#311a12',
        //     color: '#fff',
        //     width:'550px'
        // })




        const providers = await getCurrentProvider();
        const web3 = new Web3(providers);
        let fromAddress = localStorage.getItem('connectWalletAddress');
        web3.eth.defaultAccount = fromAddress;

        try {
            const Royalty_CONTRACT = await new web3.eth.Contract(ABI, Contract);

            if (web3.currentProvider.networkVersion != 1 && web3.currentProvider.networkVersion != '0x1') {
                Swal.fire({
                    text: 'Please select Ethereum network!',
                    // icon: 'warning',
                    title: "Alert",
                    confirmButtonText: 'Okay',
                    confirmButtonColor: '#ae7d5c',
                    background: '#311a12',
                    color: '#fff'
                })
                return;
            }
            setRewardBtnText('Processing...');

            const response = await getClaimDetailsAPI(fromAddress);
            if (parseFloat(response.amount) == 0) {
                setRewardBtnText('Withdraw');
    
                Swal.fire({
                    text: 'You don\'t have reward amount!',
                    // icon: 'warning',
                    title: "Notice!",
                    confirmButtonText: 'Close',
                    confirmButtonColor: '#ae7d5c',
                    background: '#311a12',
                    color: '#fff',
                    width: '550px'
                });
                return;
            }
            let amount = parseInt(parseFloat(response.amount) * 10 ** 18).toString();
            console.log(response);

            let chainId = '0x1';
            let tx_builder = await Royalty_CONTRACT.methods.claimReward(amount, response.hexproof);
            let encoded_tx = tx_builder.encodeABI();


            let gasPrice = await web3.eth.getGasPrice();
            gasPrice = parseInt(gasPrice) + 10000000000;

            let gasLimit = await web3.eth.estimateGas({
                gasPrice: web3.utils.toHex(gasPrice),
                to: Contract,
                from: fromAddress,
                chainId: chainId,
                data: encoded_tx
            });
            const transactionParameters = {
                from: fromAddress,
                gasPrice: web3.utils.toHex(gasPrice),
                gas: web3.utils.toHex(gasLimit),
                to: Contract,
                chainId: chainId,
                data: encoded_tx
            };

            // const txHash = await web3.eth.sendTransaction(transactionParameters);
            const txHash = await web3.currentProvider.send("eth_sendTransaction", [transactionParameters]);
            if (txHash.result) {
                const res = await axios({
                    method: 'post',
                    url: `${apiUrl}updateClaimAmount`,
                    data: {
                        address: fromAddress,
                        amount: response.amount,
                        hash: txHash.result,
                        hexroot_id: response.hexroot_id
                    }
                });

                Swal.fire({
                    width: 500,
                    title: 'Successful!',
                    html: 'Congratulation! Reward Claimed Successfully.',
                    // icon: 'warning',
                    title: "Alert",
                    confirmButtonText: 'Ok, Thanks',
                    confirmButtonColor: '#ae7d5c',
                    background: '#311a12',
                    color: '#fff'
                }).then((result) => {
                    window.location.reload();
                })
            }
        } catch (err) {
            console.log("err", err)
            setRewardBtnText('Withdraw');

            if (err.message.toString().split('insufficient funds')[1]) {

                Swal.fire({
                    text: 'Transaction reverted : ' + err.message,
                    // icon: 'warning',
                    title: "Alert",
                    confirmButtonText: 'Okay',
                    confirmButtonColor: '#ae7d5c',
                    background: '#311a12',
                    color: '#fff'
                })
            } else {
                if (err.toString().split('execution reverted:')[1]) {
                    Swal.fire({
                        text: 'Transaction reverted : ' + (err.toString().split('execution reverted:')[1]).toString().split('{')[0],
                        // icon: 'warning',
                        title: "Alert",
                        confirmButtonText: 'Okay',
                        confirmButtonColor: '#ae7d5c',
                        background: '#311a12',
                        color: '#fff'
                    })
                } else {
                    if (err.message.toString().search(/supplied gas/) > -1 || err.message.toString().search(/exceeds allowance/) > -1) {
                        Swal.fire({
                            text: "Transaction reverted : insufficient funds for transaction fee",
                            // icon: 'warning',
                            title: "Alert",
                            confirmButtonText: 'Okay',
                            confirmButtonColor: '#ae7d5c',
                            background: '#311a12',
                            color: '#fff'
                        })
                    } else {
                        Swal.fire({
                            text: err.message,
                            // icon: 'warning',
                            title: "Alert",
                            confirmButtonText: 'Okay',
                            confirmButtonColor: '#ae7d5c',
                            background: '#311a12',
                            color: '#fff'
                        })
                    }
                }
            }
        }

    }



    //  +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++


    const royaltyDistribute = async () => {

        if (RoyaltyBtnText === "Processing...") {
            return;
        }

        const providers = await getCurrentProvider();
        const web3 = new Web3(providers);
        let fromAddress = localStorage.getItem('connectWalletAddress');
        web3.eth.defaultAccount = fromAddress;

        try {
            const Royalty_CONTRACT = await new web3.eth.Contract(ABI, Contract);

            if (web3.currentProvider.networkVersion != 1 && web3.currentProvider.networkVersion != '0x1') {
                Swal.fire({
                    text: 'Please select Ethereum network!',
                    // icon: 'warning',
                    title: "Alert",
                    confirmButtonText: 'Okay',
                    confirmButtonColor: '#ae7d5c',
                    background: '#311a12',
                    color: '#fff'
                })
                return;
            }
            setRoyaltyBtnText('Processing...');

            const response = await axios({
                method: 'get',
                url: `${apiUrl}royaltyDistribution`,
            });
            if (!response.data.hexroot) {
                setRoyaltyBtnText('Royalty Distribute');
                Swal.fire({
                    text: response.data.msg,
                    // icon: 'warning',
                    title: "Notice!",
                    confirmButtonText: 'Close',
                    confirmButtonColor: '#ae7d5c',
                    background: '#311a12',
                    color: '#fff',
                    width: '550px'
                });
                return;
            }

            let chainId = '0x1';
            let tx_builder = await Royalty_CONTRACT.methods.setRoot(response.data.hexroot);
            let encoded_tx = tx_builder.encodeABI();


            let gasPrice = await web3.eth.getGasPrice();
            gasPrice = parseInt(gasPrice) + 10000000000;

            let gasLimit = await web3.eth.estimateGas({
                gasPrice: web3.utils.toHex(gasPrice),
                to: Contract,
                from: fromAddress,
                chainId: chainId,
                data: encoded_tx
            });
            const transactionParameters = {
                from: fromAddress,
                gasPrice: web3.utils.toHex(gasPrice),
                gas: web3.utils.toHex(gasLimit),
                to: Contract,
                chainId: chainId,
                data: encoded_tx
            };

            // const txHash = await web3.eth.sendTransaction(transactionParameters);
            const txHash = await web3.currentProvider.send("eth_sendTransaction", [transactionParameters]);
            if (txHash.result) {
                const res = await axios({
                    method: 'post',
                    url: `${apiUrl}confirmRoyaltyDistribution`,
                    data: {
                        hash: txHash.result,
                        id: response.data.id
                    }
                });

                Swal.fire({
                    width: 500,
                    title: 'Successful!',
                    html: 'Congratulation! Reward Claimed Successfully.',
                    // icon: 'warning',
                    title: "Alert",
                    confirmButtonText: 'Ok, Thanks',
                    confirmButtonColor: '#ae7d5c',
                    background: '#311a12',
                    color: '#fff'
                }).then((result) => {
                    window.location.reload();
                })
            }
        } catch (err) {
            console.log("err", err)
            setRoyaltyBtnText('Royalty Distribute');

            if (err.message.toString().split('insufficient funds')[1]) {

                Swal.fire({
                    text: 'Transaction reverted : ' + err.message,
                    // icon: 'warning',
                    title: "Alert",
                    confirmButtonText: 'Okay',
                    confirmButtonColor: '#ae7d5c',
                    background: '#311a12',
                    color: '#fff'
                })
            } else {
                if (err.toString().split('execution reverted:')[1]) {
                    Swal.fire({
                        text: 'Transaction reverted : ' + (err.toString().split('execution reverted:')[1]).toString().split('{')[0],
                        // icon: 'warning',
                        title: "Alert",
                        confirmButtonText: 'Okay',
                        confirmButtonColor: '#ae7d5c',
                        background: '#311a12',
                        color: '#fff'
                    })
                } else {
                    if (err.message.toString().search(/supplied gas/) > -1 || err.message.toString().search(/exceeds allowance/) > -1) {
                        Swal.fire({
                            text: "Transaction reverted : insufficient funds for transaction fee",
                            // icon: 'warning',
                            title: "Alert",
                            confirmButtonText: 'Okay',
                            confirmButtonColor: '#ae7d5c',
                            background: '#311a12',
                            color: '#fff'
                        })
                    } else {
                        Swal.fire({
                            text: err.message,
                            // icon: 'warning',
                            title: "Alert",
                            confirmButtonText: 'Okay',
                            confirmButtonColor: '#ae7d5c',
                            background: '#311a12',
                            color: '#fff'
                        })
                    }
                }
            }
        }

    }

    //  -----------------------------------------------------------------

    // const getRewardAmount = async () => {

    //     const providers = await getCurrentProvider();
    //     const web3 = new Web3(providers);
    //     let fromAddress = localStorage.getItem('connectWalletAddress');
    //     const NFT_CONTRACT = await new web3.eth.Contract(config.ABI, config.contractAddress);

    //     let halfAmount = 0.07677949309;
    //     let totalSupply = await NFT_CONTRACT.methods.totalSupply().call();
    //     let newAmount = halfAmount / parseInt(totalSupply);
    //     let myReward = 0;

    //     let myNFT = await NFT_CONTRACT.methods.balanceOf(fromAddress).call();
    //     myReward = myNFT * newAmount;
    //     settotalReward(!myReward ? 0.00 : myReward);
    // }



    return (

        <>
            {localStorage.getItem('connectWalletAddress') ?
                <div className='after_connect_wallet'>
                    <div>
                        <div className='wallet_address'>
                            <div className='row'>
                                <div className='col-lg-12 col-md-12 col-12'>
                                    <label>Community Wallet</label>

                                </div>
                                <div className='col-lg-12 col-md-12 col-12'>
                                    <a href="https://etherscan.io/address/0x592DAbC4703b4174851aF80BCaa09Aa3422DfBEB" target="_blank" className='address'>0x592...DfBEB</a>
                                </div>
                                <div className='col-lg-12 col-md-12 col-12'>
                                    <h6 className='text-white text-center'>{parseFloat(communityWalletBalance).toFixed(6)} ETH  ( $ {parseFloat(communityWalletBalance * ETHinUSD).toFixed(2)} )</h6>
                                    <hr />
                                    {/* <div className='col-md-6 col-6'>
                                        <h6 className='text-white'>{parseFloat(communityWalletBalance).toFixed(4)} ETH</h6>
                                    </div>
                                    <div className='col-md-6 col-6'>
                                        <h6 className='text-white'>$ {parseFloat(communityWalletBalance * ETHinUSD).toFixed(2)}</h6>
                                    </div> */}
                                </div>

                            </div>

                            <div className='row'>
                                <div className='col-lg-12 col-md-12 col-12'>
                                    <label>My Wallet</label>

                                </div>
                                <div className='col-lg-12 col-md-12 col-12'>
                                    <a href={`https://etherscan.io/address/${localStorage.getItem('connectWalletAddress')}`} target="_blank" className='address'>{localStorage.getItem('connectWalletAddress').toString().substring(0, 5) + '...' + localStorage.getItem('connectWalletAddress').toString().substr(localStorage.getItem('connectWalletAddress').length - 5)}</a>
                                </div>
                            </div>

                        </div>



                        <div className='show_balance'>
                            <p>Royalty Balance</p>
                            <div className='row'>
                                <div className='col-md-6 col-6'>
                                    <h4 className='text-white'>{parseFloat(totalReward).toFixed(6)} ETH</h4>
                                </div>
                                <div className='col-md-6 col-6'>
                                    <h4 className='text-white'>$ {parseFloat(totalReward * ETHinUSD).toFixed(2)}</h4>
                                </div>
                            </div>
                            {/* <p style={{}}>Note : </p> */}
                            <button className='btn btn-primary add_fund_btn' disabled={totalReward == 0} type='button' onClick={e => claimReward()}> {RewardBtnText}</button>
                        </div>
                        {isOnwer ?
                            <div className='show_balance' style={{ marginTop:'10px'}}>
                                {/* <hr/> */}
                                {/* <p style={{}}>Note : </p> */}
                                <p>Royalty Distribution BY Owner</p>
                                <button className='btn btn-primary add_fund_btn' type='button' onClick={e => royaltyDistribute()}> {RoyaltyBtnText}</button>
                            </div> : ""}

                    </div>
                </div> : ''}



        </>
    );
}

export default RewardMenu;   