import React, { Component, useState } from 'react';
import Header from '../directives/header';
import Footer from '../directives/footer';
import config from '../config/config';
import axios from 'axios';

const Typeofform = (props) => {

    const [firstStepData, setfirstStepData] = useState('')

    const [formData, setFormData] = useState({
        address: "",
        intriguesNFT: "",
        intriguesProject: "",
        planbuying: '',
        emailaddress: ''
    });
    const { address, intriguesNFT, intriguesProject, planbuying, emailaddress } = formData;
    //============================================  Register  ==========================

    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value }
        );
    }

    const firstStep = async (e, id) => {
        e.preventDefault()
        if (id === 1) {
            setfirstStepData(1)
        }
        else if (id === 2) {
            setfirstStepData(2)
        }
        else if (id === 3) {
            setfirstStepData(3)
        }
        else if (id === 4) {
            setfirstStepData(4)
        }
        else if (id === 5) {
            setfirstStepData(5)
            addressInsert()
        }

    }

    const addressInsert = async () => {
        const newUser = {
            address,
            intriguesNFT,
            intriguesProject,
            planbuying,
            emailaddress
        };
        try {
            const res = await axios.post(`${config.apiUrl}insertTypeform`, newUser);
        } catch (err) {
        }
    }





    return (

        <>
            <Header />

            <main className="main-content">

                <section id className="characters content-section mt-5 pb-0 latest-articles text-light">
                    {/* <section className="banner_section">
                        <div className="container text-center">
                            <h2>Type of form</h2>
                        </div>
                    </section> */}

                    <div className="content-section text-light  typeform">
                        <div className="container">
                            <div className="row gutters-y">
                                <div className="col-12">
                                    <header>
                                        <div className="row">
                                            <div className="col-sm-6 col-md-6 col-lg-4 mx-auto  md-lg-0">
                                                <div className='type-of-form'>
                                                    {/* <h2>Welcome <br />to Typeform</h2><br /> */}
                                                    <div className='row'>
                                                        {/* <div className='col-sm-2'>

                                                        </div> */}
                                                        <div className='col-sm-12'>
                                                            <div className="common-container pt-5 pb-5">
                                                                <div className="row">
                                                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-6"><img className="img-fluid" src="assets/img/Odd-Fellows.png" aria-label="ape4" /></div>
                                                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-6"><img className="img-fluid" src="assets/img/Odd-Fellows3.png" alt="ape2" aria-label="ape2" /></div>
                                                                </div>
                                                                <div className="row pt-2">
                                                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-6"><img className="img-fluid" src="assets/img/Odd-Fellows2.png" alt="ape3" aria-label="ape3" /></div>

                                                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-6"><img className="img-fluid" src="assets/img/Odd-Fellows4.png" alt="ape1" aria-label="ape1" /></div>
                                                                </div>
                                                            </div>
                                                            {/* <img src='assets/img/Odd-Fellows3.png' width="100%" /> */}

                                                        </div>
                                                        {/* <div className='col-sm-2'>

                                                        </div> */}

                                                    </div>

                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                {/* <MultiStepForm activeStep={3}>
                                                        <Step label="one">
                                                        <p>One</p>
                                                        </Step>
                                                        <Step label="Two">
                                                        <p>Two</p>
                                                        </Step>
                                                        <Step label="Three">
                                                        <p>Three</p>
                                                        </Step>
                                                    </MultiStepForm> */}
                                                <div className='all-step'>

                                                    <div className='step-one' style={{ display: firstStepData === '' ? 'block' : 'none' }}>
                                                       <h5> Thank you so much for being a part of this journey.<br/>
                                                        The whitelist form submission is now closed.<br/>
                                                        In the meantime, feel free to contact<a href='javascript:void(0)'> oddfellowsnft@gmail.com</a> with any questions.<br/>
                                                        Sincerely, the Secret Society of Odd Fellows team.</h5>
                                                        {/* <form onSubmit={e => firstStep(e, 1)} >
                                                            <h5>1). What is the address of your MetaMask or Coinbase wallet?</h5>
                                                            <p>For example: 0xC6737b3062A0589CD2FFaC551D1B293B934f1AD4</p>
                                                            <div className='form-group'>
                                                                <input type="text" onChange={onChange} name='address'
                                                                    value={address} placeholder='Type your answer here…' className='form-control' />
                                                            </div>

                                                            <button type='submit' disabled={!address} className='btn btn-default'>Next</button>&nbsp;&nbsp;

                                                            
                                                        </form> */}

                                                    </div>
                                                    <div className='step-two' style={{ display: firstStepData === 1 ? 'block' : 'none' }}>
                                                        <form onSubmit={e => firstStep(e, 2)} >
                                                            <h5>2). What intrigues you most about NFTs?</h5>
                                                            <div className='form-group'>
                                                                <input type="text" onChange={onChange} name='intriguesNFT'
                                                                    value={intriguesNFT} placeholder='Type your answer here…' className='form-control' />
                                                            </div>
                                                            <button type='submit' disabled={!intriguesNFT} className='btn btn-default p-2'>Next</button>&nbsp;&nbsp;
                                                            {/* <a><small>press <strong>Enter ↵</strong></small></a> */}
                                                        </form>
                                                    </div>
                                                    <div className='step-three' style={{ display: firstStepData === 2 ? 'block' : 'none' }}>
                                                        <form onSubmit={e => firstStep(e, 3)} >
                                                            <h5>3). What intrigues you most about our project?</h5>
                                                            <div className='form-group'>
                                                                <input type="text" onChange={onChange} name='intriguesProject'
                                                                    value={intriguesProject} placeholder='Type your answer here…' className='form-control' />
                                                            </div>
                                                            <button type='submit' disabled={!intriguesProject} className='btn btn-default p-2'>Next</button>&nbsp;&nbsp;
                                                            {/* <a><small>press <strong>Enter ↵</strong></small></a> */}
                                                        </form>
                                                    </div>
                                                    <div className='step-four' style={{ display: firstStepData === 3 ? 'block' : 'none' }}>
                                                        <form onSubmit={e => firstStep(e, 4)} >
                                                            <h5>4). How many Odd Fellow NFTs do you plan on buying?</h5>
                                                            <div className='form-group'>
                                                                <input type="text" onChange={onChange} name='planbuying'
                                                                    value={planbuying} placeholder='Type your answer here…' className='form-control' />
                                                            </div>
                                                            <button type='submit' disabled={!planbuying} className='btn btn-default p-2'>Next</button>&nbsp;&nbsp;
                                                            {/* <a><small>press <strong>Enter ↵</strong></small></a> */}
                                                        </form>
                                                    </div>
                                                    <div className='step-five' style={{ display: firstStepData === 4 ? 'block' : 'none' }}>
                                                        <form onSubmit={e => firstStep(e, 5)} >
                                                            <h5>5). What is your email address or best way to reach you?</h5>
                                                            <div className='form-group'>
                                                                <input type="email" onChange={onChange} name='emailaddress'
                                                                    value={emailaddress} placeholder='Type your answer here…' className='form-control' />
                                                            </div>
                                                            <button type='submit' disabled={!emailaddress} className='btn btn-default p-2'>Next</button>&nbsp;&nbsp;
                                                            {/* <a><small>press <strong>Enter ↵</strong></small></a> */}
                                                        </form>
                                                    </div>

                                                    <div className='step-six' style={{ display: firstStepData === 5 ? 'block' : 'none' }}>
                                                        <div className='text-center'>
                                                            <p>Thank you so much for taking the time to check us out and be a part of this journey.
                                                                <br /> We will contact you soon regarding your whitelist spot.
                                                                <br />In the meantime, feel free to contact <a href='#' className='yellow'>oddfellowsnft@gmail.com</a> with any questions.
                                                                <br />Sincerely, the Secret Society of Odd Fellows team.
                                                            </p>
                                                        </div>


                                                        {/* <a href="https://discord.com/invite/oddfellowsnft" target="_blank" className='btn btn-default'><img src="assets/img/Discord-Logo.png" width="25px" style={{ filter: 'brightness(0) invert(1)', objectFit: 'contain', width: '70px' }} /></a>
                                                        <p>Join On Discord</p> */}

                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </header>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>

            </main>

            <Footer />
        </>
    )
}

export default Typeofform;